<template>
    <BT-Dialog
        v-model="dDate"
        :icon="icon"
        :text="text"
        :buttonClass="buttonClass"
        :block="block"
        :label="label"
        :small="small"
        @ok="selectDate"
        width="350">
        <template slot-scope="{ item }">
            <v-date-picker
                :color="color"
                v-model="item.d"
                :allowed-dates="dateRules"
                :min="startDate" />
        </template>
    </BT-Dialog>

</template>

<script>
export default {
    name: 'BT-Select-Date-Dialog',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data: function() {
        return {
            //UTC
            date: null, //UTC
            showDate: null,
            startDate: null,
        }
    },
    props: {
        //UTC
        //value: null,  ///UTC Format Date
        // format: {
        //     type: String,
        //     default: 'ccc d-LL-yyyy' //'ddd D-MMM-YYYY'//'ddd D-MMM-YYYY HH:mm:ss A'
        // },
        block: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        //UTC
        dateFrom: {
            type: String,
            default: null
        },
        fromNow: {
            type: Boolean,
            default: false,
        },
        dateRules: {
            type: Function,
            default: null
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dDate() {
            return {
                d: this.date
            }
        }
    },
    watch: {
        // //UTC
        // value: function(val) {            
        //     this.date = this.$BlitzIt.auth.formTZ(val, 'yyyy-LL-dd');
        // },
        //UTC
        dateFrom: function(val) {
            if (val) {
                this.startDate = val;
            }
        }
    },
    mounted() {
        if (this.value) {
            this.date = this.$BlitzIt.auth.formTZ(this.value, 'yyyy-LL-dd');
        }  
        
        if (this.dateFrom == null && this.fromNow) {
            this.startDate = this.$BlitzIt.auth.createUTC();
        }
        else {
            this.startDate = this.dateFrom;
        }
    },
    methods: {
        selectDate(nv) {
            this.$emit('ok', nv.d);
        }
    }
}
</script>